import { Chain } from "@app/domains/chain/models";
import { PageFooter } from "@app/domains/shared/page-wrapper";

type ChainFooterProps = {
  chain: Chain;
};

export const ChainFooter: React.VFC<ChainFooterProps> = ({ chain }) => {
  const socialMediaLinks = chain.getSocialMediaLinks();
  const institutionalLinks = chain.getInstitutionalLinks();
  return (
    <PageFooter
      socialMediaLinks={socialMediaLinks}
      institutionalLinks={institutionalLinks}
    />
  );
};

import { Chain, MerchantResponse } from "@app/domains/chain/models";
import { Icon } from "@app/domains/shared/design-system";

import * as S from "./styles";
import {
  DeliveryFilled,
  DeliveryPersonFilled,
  PinFilled,
} from "@ifood/pomodoro-icons";
import Link from "next/link";
import { CHAIN_LOGO_URL } from "@app/domains/shared/constants";

type MerchantCardProps = {
  chain: Chain;
  bestOption?: boolean;
  merchant: MerchantResponse;
  onRedirect: () => void;
};

export const MerchantCard: React.VFC<MerchantCardProps> = ({
  bestOption,
  chain,
  merchant,
  onRedirect,
}) => {
  const logoUrl = `${CHAIN_LOGO_URL}/${chain.logoUrl}`;

  return (
    <Link href={`/${chain.slug}/${merchant.id}`} passHref={true}>
      <S.MerchantCard onClick={onRedirect}>
        {bestOption && <S.BestOptionTag>Melhor escolha</S.BestOptionTag>}

        <S.CardRow>
          <S.CardLogo
            src={logoUrl}
            fallbackSrc="/images/chain/logo_fallback.png"
            objectFit="contain"
            layout="fixed"
            width={48}
            height={48}
          />
          <S.CardTitle>{merchant.name}</S.CardTitle>
        </S.CardRow>

        <S.CardInfo>
          <Icon component={PinFilled} size="xs" />
          {`${merchant.address.address}, ${merchant.address.streetNumber} - ${merchant.address.district}, ${merchant.address.city}, ${merchant.address.state} - ${merchant.address.zipCode}`}
        </S.CardInfo>

        <S.CardRow mt="auto">
          {merchant.features.includes("DELIVERY") && (
            <S.CardInfo>
              <Icon component={DeliveryFilled} size="xs" />
              Entrega
            </S.CardInfo>
          )}
          {merchant.features.includes("TAKEOUT") && (
            <S.CardInfo>
              <Icon component={DeliveryPersonFilled} size="xs" />
              Retirada
            </S.CardInfo>
          )}
        </S.CardRow>
      </S.MerchantCard>
    </Link>
  );
};
